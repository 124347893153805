import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  // old url
  //const url = "https://www.youtube.com/watch?v=cqp8I2ZHC8Q&list=PLEuRY8fOY4OiBSU2dDItW9aFGAU8B-4xF&index=1";
 
  // date : 2021-09-13 : new url to playlist
  const url = "https://youtube.com/playlist?list=PLEuRY8fOY4OiBSU2dDItW9aFGAU8B-4xF";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;

